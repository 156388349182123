import { debounce } from 'lodash'
import { mapActions, mapState } from 'pinia'
import { loadingMixin } from 'abcreche-ui'
import { useRegistrationsStore } from '~/stores/registrations'
import RegistrationRepository from '~/repositories/Creche/RegistrationRepository'

export default {
    mixins: [loadingMixin],
    data () {
        return {
            registrations: [],
            registrationsPagination: {},
            registrationsQueryParameters: {},
            registrationsPaginationShouldAppend: false
        }
    },

    computed: {
        ...mapState(useRegistrationsStore, { registrationFilters: 'filters', registeredRegistrations: 'registeredRegistrations' }),
        registrationsOptions () {
            return this.registrations.map((r) => {
                return {
                    value: r.uuid,
                    label: r.baby.first_name + ' ' + r.baby.last_name
                }
            })
        },
        registeredRegistrationOptions () {
            return this.registeredRegistrations
                .map((registration) => {
                    return {
                        value: registration.uuid,
                        label: registration.baby.name
                    }
                })
        }
    },

    methods: {
        ...mapActions(useRegistrationsStore, {
            setRegistrationFilterInStore: 'setFilter',
            fetchRegisteredRegistrations: 'fetchRegisteredRegistrations'
        }),

        searchRegistrations: debounce(function (page = 1) {
            this.load('registrations.search')

            return RegistrationRepository.index({
                ...this.registrationFilters,
                ...this.registrationsQueryParameters,
                page
            })
                .then((response) => {
                    if (this.registrationsPaginationShouldAppend) {
                        this.registrations = this.registrations.concat(response.data)
                    } else {
                        this.registrations = response.data
                    }
                    this.registrationsPagination = response.meta
                    this.done(null, 'registrations.search')
                })
                .catch(() => {
                    this.$toast.error({
                        title: 'Erreur Serveur',
                        message: 'Il y a une erreur lors de la requête',
                        duration: 20000
                    })
                })
        }, 500),

        setRegistrationsFilter (key, value) {
            this.setRegistrationFilterInStore({ key, value })

            this.searchRegistrations(1)
        }
    }
}
